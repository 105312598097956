/* eslint-disable */
import { memo, useEffect, useMemo, useState } from 'react';
import { ChartOptions } from 'chart.js';
import MaterialCard from '@common/ui/Mui/Card/MaterialCard';
import { Button, Grid, useMediaQuery } from '@mui/material';
import {
  formatCurrency,
  formatMonth,
  formatNumberToUSStandard
} from '@pages/dashboard/common/helper';
import { Repeat } from '@assets/icons';
import { useTheme } from '@mui/material/styles';
import styles from './potential-savings.module.scss';
import usePotentialSavingsData from './hooks/usePotentialSavingsData';
import { IFtoProps } from './type';
import BarChartSkeleton from '../common/common';
import warningImage from '@assets/images/exclamation-triangle.svg'; // Import the image
import BarChart from '@common/ui/Chart/BarChart/BarChart';
import { Plugin } from 'chart.js';

const PotentialSavings = ({ width = '100%', height = '25.7rem', isParentLoading }: IFtoProps) => {
  const { potentialSavingsData, isLoading, showError, aggData, fetchPotentialSavingsData } =
    usePotentialSavingsData(isParentLoading);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md')); // 1152 px
  const isMd2 = window.innerWidth > 950 && window.innerWidth < 970; /** range between 950 to 970 */
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme.breakpoints.only('xl'));

  const customSymbol = useMemo<Plugin<'bar'>>(() => {
    const imageCache = new Map<number, HTMLImageElement>();
    const imagePositions = new Map<number, { x: number; y: number }>();

    return {
      id: 'customSymbol',
      afterDatasetsDraw: chart => {
        const ctx = chart.ctx;
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';

        if (chart.data.labels) {
          chart.data.labels.forEach((label, index) => {
            let allZero = true;
            chart.data.datasets.forEach(dataset => {
              const data: any = dataset;
              if (data.tolltransaction_count[index] !== 0) {
                allZero = false;
              }
            });

            if (allZero) {
              const meta = chart.getDatasetMeta(0);
              const bar = meta.data[index];

              const x = bar.x - 20; // Adjust to center the image
              const y = chart.scales.y.getPixelForValue(0) - 40; // Adjust to position the image above the x-axis

              // Cache the image
              if (!imageCache.has(index)) {
                const image = new Image();
                image.src = warningImage; // Use the imported image
                image.onload = () => {
                  imageCache.set(index, image);
                  imagePositions.set(index, { x, y });
                  ctx.drawImage(image, x, y, 40, 40); // Draw the image
                };
              } else {
                const cachedImage = imageCache.get(index);
                if (cachedImage) {
                  ctx.drawImage(cachedImage, x, y, 40, 40); // Draw the cached image
                }
              }
            }
          });
        }
      },
      afterDraw: chart => {
        chart.canvas.addEventListener('mousemove', event => {
          const mouseX = event.offsetX;
          const mouseY = event.offsetY;

          let tooltipEl = document.getElementById('custom-tooltip');

          // Create tooltip element if it does not exist
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'custom-tooltip';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.backgroundColor = 'rgba(0, 0, 0, 0.7)';
            tooltipEl.style.color = 'white';
            tooltipEl.style.padding = '5px';
            tooltipEl.style.borderRadius = '3px';
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.opacity = '0';
            document.body.appendChild(tooltipEl);
          }

          // Check if mouse is over any image
          let isTooltipVisible = false;
          imagePositions.forEach((position, index) => {
            const { x, y } = position;

            if (mouseX > x && mouseX < x + 40 && mouseY > y && mouseY < y + 40) {
              if (tooltipEl) {
                // Check if tooltipEl is not null
                tooltipEl.innerHTML = 'No toll bill uploaded.'; // Set the text for the tooltip

                // Position tooltip above the image
                tooltipEl.style.opacity = '1';
                tooltipEl.style.left = event.clientX + 10 + 'px'; // Add some padding to position
                tooltipEl.style.top = event.clientY - 50 + 'px'; // Position above the image (adjust -50 based on your image height and tooltip size)
                isTooltipVisible = true;
              }
            }
          });

          if (!isTooltipVisible && tooltipEl) {
            // Ensure tooltipEl is not null
            tooltipEl.style.opacity = '0';
          }
        });
      }
    };
  }, [warningImage]);

  const [chartOptions, setChartOptions] = useState<ChartOptions<'bar'>>({
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      autoPadding: true,
      padding: {
        right: 80, // lg : 130 xl : 40 md : 200 sm : 0 xs : 0
        bottom: 80 // lg : 80 xl : 80 md : 80 sm : 0 xs : 0
      }
    },

    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        reverse: true,
        align: 'center',
        labels: {
          usePointStyle: true,
          pointStyle: 'rect'
        }
      },
      datalabels: {
        display: true,
        anchor: 'end',
        align: 'top',
        formatter: (value, context) => {
          let dataArray: any = [];
          let allZero = true;
          context.chart.data.datasets.forEach(dataset => {
            if (dataset.data[context.dataIndex]) {
              dataArray.push(dataset.data[context.dataIndex]);
            }
            let data: any = dataset;
            if (data.tolltransaction_count[context.dataIndex] !== 0) {
              allZero = false;
            }
          });
          function totalSum(total: any, data: any) {
            return total + data;
          }
          let sum = dataArray.reduce(totalSum, 0);
          if (!allZero) {
            if (context.datasetIndex === 3) {
              return `$${Math.floor(sum).toLocaleString()}`;
            } else {
              return '';
            }
          } else {
            return '';
          }
        }
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title: function (context) {
            return formatMonth(context[0].label);
          },
          label: function (context) {
            const datasetLabel = context.dataset.label || '';
            const value = context.raw !== undefined ? context.raw : 0;
            let data: any = context.dataset;
            let instance_count = data.instanceCount[context.dataIndex];

            const Amount = `$${Math.floor(
              Number(value)
            ).toLocaleString()} (${instance_count.toLocaleString()})`;
            return [datasetLabel, Amount];
          }
        }
      }
    },
    scales: {
      y: {
        stacked: true,
        title: {
          display: false
        },
        min: 0,
        ticks: {
          display: true,
          font: {
            weight: 'bold'
          },
          callback: (value: string | number) => {
            if (typeof value === 'number') {
              if (value >= 100000) {
                return `$${(value / 1000).toLocaleString()}K`;
              }
              return `$${value.toLocaleString()}`;
            }
            return value;
          }
        }
      },
      x: {
        stacked: true,
        grid: {
          display: false
        },
        ticks: {
          font: {
            weight: 'bold'
          }
        }
      }
    }
  });

  useEffect(() => {
    let newPaddingRight = 10;
    let newPaddingBottom = 20;

    switch (true) {
      case isXs:
      case isSm:
        newPaddingRight = 5;
        newPaddingBottom = 0;
        break;
      case !isMd2 && isMd:
        newPaddingRight = 10;
        newPaddingBottom = 20;
        break;
      case isMd2:
        newPaddingRight = 15;
        newPaddingBottom = 20;
        break;
      case isLg:
        newPaddingRight = 20;
        break;
      case isXl:
        newPaddingRight = 20;
        break;
      default:
        newPaddingRight = 20;
        break;
    }

    setChartOptions(prevOptions => ({
      ...prevOptions,
      layout: {
        ...prevOptions.layout,
        padding: {
          right: newPaddingRight,
          bottom: newPaddingBottom
        }
      }
    }));
  }, [isXs, isSm, isMd, isMd2, isLg, isXl]);
  return (
    <MaterialCard
      title="Potential Savings"
      minHeight="60vw"
      cardHeight="100%"
      noGrid
      helpLocation="potential_savings"
      hasBorder
    >
      {isLoading && (
        <>
          <BarChartSkeleton />
        </>
      )}
      {!isLoading && !showError && potentialSavingsData && (
        <Grid container>
          <Grid container className={styles.gridContainer}>
            <p className={styles.textContainer}>
              <span className={styles.totalText}>12-month</span>
              <span className={styles.totalText}>Lifetime</span>
            </p>
            <p className={styles.textContainer}>
              <span className={styles.totalTextvalue}>
                {formatCurrency(aggData.totalAmount.toString(), true)}
              </span>
              <span className={styles.totalTextvalue}>
                {formatCurrency((aggData.grandTotalAmount ?? 0).toString(), true)}
              </span>
            </p>
            <p className={styles.textContainer}>
              <span className={styles.totalTolls}>
                ({formatNumberToUSStandard(aggData.totalTolls)})
              </span>
              <span className={styles.totalTolls}>
                ({formatNumberToUSStandard(Number(aggData.grandTotalCount) ?? 0)})
              </span>
            </p>
          </Grid>
          <Grid container xs={12} sm={12} md={12} lg={12} className={styles.barChart}>
            <BarChart
              data={potentialSavingsData}
              width="100%"
              options={chartOptions}
              plugins={[customSymbol]}
            />
          </Grid>
        </Grid>
      )}
      {!isLoading && (showError || !potentialSavingsData) && (
        <div className={styles.error}>
          Something went wrong!
          <div className={styles.retry}>
            <Button
              size="small"
              variant="contained"
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={fetchPotentialSavingsData}
              startIcon={<Repeat className={styles.icon} />}
            >
              Retry
            </Button>
          </div>
        </div>
      )}
    </MaterialCard>
  );
};

export default memo(PotentialSavings);
