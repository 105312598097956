/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable */
import { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import api from '@api/services/common';
import { snakeCase } from 'lodash';
import { useAppSelector } from '@store/hooks';
import { IApiResponse, IBarChartData, IDate, IOvercharges, IOverchargesTotals } from '../type';

const useOverchargesData = (
  isParentLoading: boolean
): {
  overchargesData: IBarChartData | undefined;
  isLoading: boolean;
  showError: boolean;
  aggData: IOverchargesTotals;
  fetchOverchargesData: () => Promise<void>;
} => {
  const [overchargesData, setOverchargesData] = useState<IBarChartData>();
  const [isLoading, setIsLoading] = useState(false);
  const [aggData, setAggData] = useState<IOverchargesTotals>({
    totalAmount: 0,
    totalTolls: 0
  });
  const [showError, setShowError] = useState(false);
  const customerId = useAppSelector(state => state.customer.id);

  const getTotalOvercharges = (data: IOvercharges[]): IOverchargesTotals => {
    return data.reduce(
      (totals, current) => {
        if (current.amount && current.amount !== null) {
          totals.totalAmount += Number(current.amount);
        }
        if (current.instance_count && current.instance_count !== null) {
          totals.totalTolls += Number(current.instance_count);
        }
        return totals;
      },
      { totalAmount: 0, totalTolls: 0 }
    );
  };

  const transformDataForBarChart = (data: IOvercharges[], dateRange: IDate[]) => {
    const sortedData = data
      .slice()
      .sort((a, b) => new Date(a.month_date).getTime() - new Date(b.month_date).getTime());

    const filterAndMapData = (subcategoryFilter: string) => {
      return sortedData
        .filter(item => snakeCase(item.subcategory) === subcategoryFilter)
        .map(item => (item.amount ? parseFloat(item.amount as unknown as string) : 0));
    };
    const filterAndMapInstanceCount = (subcategoryFilter: string) => {
      return sortedData
        .filter(item => snakeCase(item.subcategory) === subcategoryFilter)
        .map(item =>
          item.instance_count ? parseFloat(item.instance_count as unknown as string) : 0
        );
    };
    const filterAndMapTollDocUploaded = (subcategoryFilter: string) => {
      return sortedData
        .filter(item => snakeCase(item.subcategory) === subcategoryFilter)
        .map(item => (item.tolltransaction_count ? Number(item.tolltransaction_count) : 0));
    };

    const labels = dateRange.map(item => moment(item.month_date).format('MMM'));
    const chartData: IBarChartData = {
      labels,
      datasets: [
        {
          label: 'Max Tolls',
          data: filterAndMapData('max_tolls'),
          backgroundColor: '#A3C8E7',
          borderRadius: 2,
          instanceCount: filterAndMapInstanceCount('max_tolls'),
          tolltransaction_count: filterAndMapTollDocUploaded('max_tolls')
        },
        {
          label: 'Misclassifications',
          data: filterAndMapData('misclassifications'),
          backgroundColor: '#739CBD',
          borderRadius: 2,
          instanceCount: filterAndMapInstanceCount('misclassifications'),
          tolltransaction_count: filterAndMapTollDocUploaded('misclassifications')
        },
        {
          label: 'Duplicates',
          data: filterAndMapData('duplicate_tolls'),
          backgroundColor: '#437394',
          borderRadius: 2,
          instanceCount: filterAndMapInstanceCount('duplicate_tolls'),
          tolltransaction_count: filterAndMapTollDocUploaded('duplicate_tolls')
        },
        {
          label: 'Plate Tolls',
          data: filterAndMapData('plate_tolls'),
          backgroundColor: '#004C6D',
          borderRadius: 2,
          instanceCount: filterAndMapInstanceCount('plate_tolls'),
          tolltransaction_count: filterAndMapTollDocUploaded('plate_tolls')
        }
      ]
    };
    const totals = getTotalOvercharges(data);
    aggData.totalTolls = totals.totalTolls;
    aggData.totalAmount = totals.totalAmount;
    aggData.grandTotalAmount = data[0] ? data[0].grand_total : 0;
    aggData.grandTotalCount = data[0] ? data[0].grand_total_count : 0;
    setAggData({ ...aggData });
    setOverchargesData(chartData);
  };

  const fetchOverchargesData = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const response: IApiResponse = await api.post('/insights/overcharges', {
        moduleId: 'INSIGHTS'
      });
      setShowError(false);
      if (response && response.isSuccess && response.data) {
        transformDataForBarChart(response.data.data, response.data.dateRange);
      } else {
        setShowError(true);
      }
    } catch (err) {
      console.log('Err in calling Overcharges data ==> ', err);
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isParentLoading) {
      fetchOverchargesData().catch(err => {
        console.log('Error in Overcharges data ==> ', err);
      });
    }
  }, [customerId]);

  return {
    overchargesData,
    isLoading,
    showError,
    aggData,
    fetchOverchargesData
  };
};

export default useOverchargesData;
