export const transactionHelperTypes = [
  {
    label: 'Maximum Tolls',
    value: 1
  },
  {
    label: 'Exact Duplicates',
    value: 2
  },
  {
    label: 'License Plate Tolls',
    value: 3
  },
  {
    label: 'Near Duplicates',
    value: 4
  },
  {
    label: 'Overcharges',
    value: 5
  },
  {
    label: 'Unknown Vehicle Tolling',
    value: 6
  }
];

export const fleetHelperTypes = [
  {
    label: 'Missing Transponder ',
    value: 1
  },
  {
    label: 'Missing License Plate ',
    value: 2
  }
];
