/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable */
import { ChartOptions } from 'chart.js';
import { memo, useState } from 'react';
import MaterialCard from '@common/ui/Mui/Card/MaterialCard';
import { Grid } from '@mui/material';
import LineChart from '@common/ui/Chart/LineChart/LineChart';
import styles from './average-toll-per-hour.module.scss';
import { ILineChartData } from '../types';
import {
  formatMonth,
  formatNumberToCurrency,
  formatNumberToUSStandard
} from '@pages/dashboard/common/helper';
import { Circle } from '@react-google-maps/api';

const AverageTollPerHour = (lineData: any) => {
  const width = '100%';
  const height = '31rem';

  const data = lineData?.line_data;
  const invoiced_total = data?.datasets[0]?.total || 0;
  const tollogic_total = data?.datasets[1]?.total || 0;
  const difference = invoiced_total - tollogic_total || 0;

  const [lineChartOptions, setChartOptions] = useState<ChartOptions<'line'>>({
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      autoPadding: false
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: function (context) {
            return formatMonth(context[0].label);
          },

          label: function (context) {
            return '  $' + formatNumberToUSStandard(context.parsed.y) + '/hour';
          }
        }
      },
      legend: {
        display: false
      },
      datalabels: {
        display: false
      }
    },
    scales: {
      y: {
        title: {
          display: false
        },
        ticks: {
          font: {
            weight: 'bold'
          },
          callback: (value: string | number) => {
            if (typeof value === 'number') {
              if (value >= 100000) {
                return `$${(value / 1000).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}K`;
              }
              return `$${value.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}`;
            }
            return value;
          }
        }
      },
      x: {
        grid: {
          display: false
        },
        ticks: {
          font: {
            weight: 'bold'
          }
        }
      }
    },
    elements: {
      point: {
        hitRadius: 5,
        radius: context => {
          let index = context.dataIndex;
          let value: any = context.dataset.data[index];
          return value == 0 ? 4 : 0;
        },
        hoverRadius: 5
      }
    }
  });
  return (
    <MaterialCard
      title="Average Toll Per Hour"
      cardVariant="child"
      cardWidth={width}
      cardHeight={height}
      noGrid
      showHeaderArrow
    >
      <Grid container spacing={2} className={styles.gridContainer}>
        <Grid item xs={9} sm={9} md={9} lg={9} xl={9.7}>
          <LineChart data={data} options={lineChartOptions} />
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={2.3}>
          <div className={styles.total}>
            <div className={styles.totalItem}>
              <div className={`${styles.squareBox} ${styles.invoiced}`} />
              <h4>Invoiced</h4>
            </div>
            <p className={styles.value}>
              {formatNumberToCurrency(Number(invoiced_total), '$')}/hour
            </p>
            <div className={styles.totalItem}>
              <div className={`${styles.squareBox} ${styles.tollogic}`} />
              <h4>tollogic™</h4>
            </div>
            <p className={styles.value}>
              {formatNumberToCurrency(Number(tollogic_total), '$')}/hour
            </p>
            <div className={styles.totalItem}>
              <div className={styles.squareBox} />
              <h4>Difference</h4>
            </div>
            <p className={styles.value}>{formatNumberToCurrency(difference, '$')}/hour</p>
          </div>
        </Grid>
      </Grid>
    </MaterialCard>
  );
};
export default memo(AverageTollPerHour);
