export interface ICategoryData {
  category: string;
  subcategory: string;
  subcategory_name: string;
  amount: string;
  instance_count: string;
  score: number;
  isClickable?: boolean;
  veh_id?: string[];
  screen?: string;
}

export interface ICategoryApiResponse {
  isSuccess: boolean;
  data: ICategoryData[];
}

export const categoryAmountsToHide = ['Partial GPS', 'Missing GPS', 'OEM Issue'];
export const savingsCategory = [
  {
    label: 'MAXIMUMTOLLS',
    isClickable: true,
    screen: 'transactions'
  },
  {
    label: 'DUPLICATE',
    isClickable: true,
    screen: 'transactions'
  },
  {
    label: 'MISCLASSIFICATIONS',
    isClickable: false,
    screen: 'transactions'
  },
  {
    label: 'LICENSEPLATETOLLS',
    isClickable: true,
    screen: 'transactions'
  },
  {
    label: 'POTENTIALMISUSE',
    isClickable: false,
    screen: 'transactions'
  },
  {
    label: 'DELAYEDPOSTINGS',
    isClickable: false,
    screen: 'fleet'
  },
  {
    label: 'TRANSPONDERFIXES',
    isClickable: false,
    screen: 'fleet'
  },
  {
    label: 'PLATEFIXES',
    isClickable: false,
    screen: 'transactions'
  },
  {
    label: 'NOVALUETRIPS',
    isClickable: false,
    screen: 'fleet'
  },
  {
    label: 'POTENTIALVIOLATION',
    isClickable: false,
    screen: 'fleet'
  }
];
