import { ArrowLeft } from '@assets/icons';
import Button from '@common/ui/Button';
import { ETableName } from '@common/utils/enums';
import { clearFilters } from '@store/features/filters';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useNavigate } from 'react-router-dom';
import { GoBackProps } from './types';

const GoBack = ({ destination, onClick, showDestination = false }: GoBackProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tableState = useAppSelector(state => state.table.name) as ETableName;
  const clearTableState = () => {
    dispatch(clearFilters(tableState));
  };
  const handleClick = () => {
    clearTableState();
    if (!destination && !onClick) {
      navigate(-1);
    } else if (!destination && onClick) onClick();
  };
  let destinationUrl;

  if (destination) {
    if (destination === 'dashboard') {
      destinationUrl = '/dashboard?tab=monthly';
    } else {
      destinationUrl = `/${destination}`;
    }
  } else {
    destinationUrl = undefined;
  }
  return (
    <Button
      variant="primary"
      label={
        showDestination && destination ? `Back to ${destination.split('_').join(' ')}` : 'Back'
      }
      link={destinationUrl}
      appearance="text"
      size="s"
      icon={<ArrowLeft />}
      onClick={handleClick}
    />
  );
};

export default GoBack;
